* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.header-main-Y {
    .header {
        padding: 0px 0px 0px 10px;
        width: 88%;
        background-color: #fff;
        margin: 0 auto;
        margin-top: 40px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .fixed-top {
        position: absolute;
    }

    .header ul {
        text-align: center;
        margin: 0;
    }

    .header ul li {
        list-style: none;
        display: inline-block;
    }

    .header ul li a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        color: #000;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: 2px;
        /* padding: 25px; */
        padding: 15px 25px 15px 25px;
        transition: all ease 0.5s;
        font-size: 12px;
        font-weight: 700;
    }

    .header ul li a:hover {
        background-color: #ddd;
    }

    img {
        width: 180px;
        mix-blend-mode: darken;
    }

    .phone {
        display: block;
    }

    .p-detail {
        display: block;
        background: #D07726;
        border-bottom-left-radius: 30px;
        display: flex;
        height: 70px;
        color: #fff;
        font-size: 14px;
        font-weight: 100;
        align-items: center;
        gap: 10px;
        padding-left: 30px;
    }

    .login {
        order: 2;
        flex-grow: 2;
        text-align: right;
        padding-right: 30px;
    }

    .login a {
        color: #000;
        font-weight: 700;
        text-decoration: none;
    }

    .email {
        padding-left: 15px;
    }

    .call,
    .email {
        color: white;
    }

    .navbar-collapse {
        margin: 10px 0;
    }

    #trackorder {
        background: #D07726;
        border-bottom-right-radius: 30px;
    }

    #loginBtMenu {
        display: none;
    }

    @media (max-width: 992px) {
        .header {
            padding: .5rem 0rem;
        }

        .p-detail {
            border-bottom-right-radius: 30px;
        }

        #loginBtMenu {
            display: inline-block;
        }
    }

    @media (min-width: 992px) {
        .navbar-nav {
            gap: 50px;
        }
    }

    @media (max-width: 576px) {
        .p-detail {
            display: none;
        }
    }

    @media (max-width: 480px) {

        .navbar-toggler,
        .navbar-toggler:active,
        .navbar-toggler:hover,
        .navbar-toggler:focus {
            margin-left: 64px;
            border: 2px solid #D07726 !important;
        }

        .header {
            padding: 10px;
        }

        .right_toggle_menu {
            width: 100%;
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(208 119 38)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }
}

// full navbar CSS

.header-main-Y.full-navbar {
    .header {
        border-radius: 0;
        margin: 0;
        width: 100%;
    }
}

/* slider css */

.home-slider-main-Y {
    .banner-slider {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: grid;
        align-items: end;
        padding-bottom: 90px;
        background-image: url('./assets/images/banner.jpg');
        width: 100%;
        height: 100vh;
    }

    .slider_container {
        padding: 0 80px;
    }

    .slide_heading {
        color: #fff;
    }

    .slide_heading h2 {
        font-weight: 300;
        font-size: 35px;
        margin-bottom: 60px;
    }

    /* .container {
        height: 650 px;
        color: #fff;
        background-image: url("../../../assets/images/banner.jpg");
        background-size: 100%;
        background-size: cover;
        height: 1000px;
      } */
    .container.slider_container a.join_us {
        padding: 10px 85px 10px 25px;
        border: none;
        background: linear-gradient(-134deg, #d07726, #d07726 25%, white 25%, white 51%, white 51%);
        font-size: 15px;
        font-weight: bold;
        color: #000;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 15px;
        cursor: pointer;
        text-decoration: none;
    }

    .container.slider_container button.download_app {
        padding: 10px 30px;
        border: none;
        background-color: #D07726;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }

    @media (max-width: 480px) {
        .slider_container {
            // margin: 0 25px;
        }

        .container.slider_container button.join_us {
            margin-bottom: 0;
        }
    }
}

/* our services css */
.our-services-main-Y {
    .slider {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('./assets/images/our-services.jpg');
        width: 100%;
        height: 100%;
    }

    .slide_heading {
        bottom: 0;
        position: absolute;
    }

    .slide_heading {
        color: #fff;
        font-weight: 300;
    }

    .our_services {
        text-align: center;
        width: 100%;
        padding: 100px 0px;
    }

    .our_services h2 {
        color: #fff;
        font-size: 45px;
    }

    hr {
        width: 16%;
        margin: 0 auto;
        height: 4px;
        background: #d07726;
        border-top: none;
        opacity: unset;
    }

    .our_services p {
        color: #fff;
        font-size: 20px;
        margin: 25px 0;
    }

    .our_services_card h4 {
        background: #d07726;
        font-size: 15px;
        padding: 10px;
        font-weight: bold;
    }

    .our_services_card .card {
        background: #d07726;
        border-radius: 0;
    }

    .our_services_card .card p {
        color: #000;
        font-size: 15px;
        padding: 90px 30px 50px 30px;
        font-weight: bold;
        text-align: left;
        line-height: 18px;
    }

    .our_services_card .card a {
        background: #fff;
        font-size: 15px;
        padding: 10px;
        font-weight: bold;
        margin: 0 30px 10px 30px;
        text-transform: uppercase;
    }

    @media (max-width: 576px) {
        .our_services {
            padding: 100px 25px;
        }
    }

    @media (max-width: 480px) {

        .our_services_card.transporter h4 {
            margin-top: 30px;
        }

        .our_services[_ngcontent-dha-c100] {
            padding: 100px 30px;
        }
    }
}

// why us section CSS

.why-us-main-Y {
    .slider {
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('./assets/images/why-us.jpg');
        width: 100%;
        height: 100%;
    }

    .slide_heading {
        bottom: 0;
        position: absolute;
    }

    .slide_heading {
        color: #fff;
        font-weight: 300;
    }

    .why_us {
        text-align: center;
        width: 100%;
        padding: 100px 0px;
    }

    .why_us h2 {
        color: #fff;
        font-size: 45px;
        margin-bottom: 25px;
    }

    hr {
        width: 16%;
        margin: 0 auto;
        height: 4px;
        background: #d07726;
        border-top: none;
        opacity: unset;
    }

    .why_us p.text {
        color: #fff;
        font-size: 24px;
        margin: 25px 80px;
        text-align: justify;
    }

    .why_us_mid_section,
    .why_us_mid_two_section {
        margin: 45px 80px;
    }

    .why_us_left_text {
        margin-right: 60px;
    }

    .why_us_left_text p {
        color: #fff;
        font-size: 24px;
        text-align: left;
    }

    .why_us_right_img img {
        width: 400px;
    }

    .why_us_left_text a {
        background: #d07726;
        font-size: 15px;
        padding: 10px 30px;
        font-weight: bold;
        float: left;
        color: #000;
        text-decoration: auto;
        margin-top: 28px;
    }

    .why_us_mid_two_section .why_us_head {
        margin-bottom: 25px;
    }

    @media (max-width: 992px) {

        .why_us p.text,
        .why_us_left_text {
            margin: 25px 0;
            text-align: justify;
        }

        .why_us_mid_section {
            margin: 45px 0;
        }

        .why_us_mid_two_section {
            margin: 45px 0px;
        }
    }

    @media (max-width: 480px) {
        .why_us_mid_section {
            margin: 0;
        }

        .why_us p.text,
        .why_us_left_text {
            margin: 25px;
            text-align: center;
            margin-bottom: 50px;
        }

        .why_us_mid_two_section {
            margin: 0;
            margin-top: 70px;
        }

        .why_us_left_text p {
            text-align: center;
        }

        .why_us_left_text a {
            float: none;
        }

        .why_us_right_img img {
            width: 100%;
            padding: 0 25px;
        }
    }
}

.contact_us_main {
    .contact_us {
        text-align: center;
        width: 100%;
    }

    .contact_us_wrapper {
        background: #062a74;
        padding: 80px 100px 40px;
    }

    .contact_us .cards {
        background: #fff;
        padding: 25px 30px;
        height: 155px;
    }

    .contact_us i {
        color: #89d7e3;
        font-size: 50px;
    }

    .contact_us h4 {
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px;
        font-weight: 800;
    }

    .contact_us p {
        color: #000;
        font-size: 12px;
        font-weight: 500;
    }

    .form {
        background: #89d7e3;
        padding: 80px 70px 55px 70px;
        position: relative;
        bottom: 40px;
    }

    .card_wrapper {
        z-index: 999999;
        position: relative;
    }

    h2 {
        font-size: 32px;
        color: #062a74;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .form-control {
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .form_button button {
        background: #fff0;
        border: 2px solid #fff;
        color: #062a74;
        padding: 7px 25px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
    }

    .fa-envelope {
        font-size: 35px !important;
    }

    @media (max-width: 992px) {
        .contact_us_wrapper {
            background: #062a74;
            padding: 80px 60px 40px;
        }
    }

    @media (max-width: 768px) {
        .Main_address.cards {
            margin-bottom: 10px;
        }

        .card_wrapper {
            margin-bottom: 20px;
        }

        .form {
            position: static;
        }
    }

    @media (max-width: 480px) {
        .contact_us_wrapper {
            padding: 50px 25px;
        }

        .form {
            padding: 69px 15px 30px 15px;
        }
    }

}

.our_partner_main {
    .our_partners {
        width: 100%;
    }

    .our_partners_wrapper {
        background: #D07726;
        padding: 50px 100px;
    }

    .our_partners h2 {
        color: #fff;
        font-size: 40px;
        margin-bottom: 4px;
        text-transform: uppercase;
        font-weight: 700;
    }

    hr {
        width: 16%;
        margin: 0 auto;
        height: 4px;
        background: #d07726;
        border-top: none;
        opacity: unset;
    }

    .our_partners p {
        color: #fff;
        font-size: 24px;
        text-align: left;
    }

    .our_partners_img {
        width: 230px;
        margin: 0 auto;
    }

    .our_partners_img img {
        width: 100%;
        mix-blend-mode: darken;
    }

    .our_partners_wrapper_img {
        margin: 150px 0;
    }

    @media (max-width: 480px) {
        .our_partners_wrapper {
            padding: 50px 25px;
        }

        .our_partners_img {
            width: 200px;
            margin: 0 auto;
            margin-bottom: 35px;
        }
    }

}

.our_client_main {
    .slider {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('./assets/images/our-clients-bg.jpg');
        width: 100%;
        height: 100%;
    }

    .slide_heading {
        bottom: 0;
        position: absolute;
    }

    .slide_heading {
        color: #fff;
        font-weight: 300;
    }

    .our_client {
        width: 100%;
        padding: 100px 100px;
    }

    .our_client h2 {
        color: #fff;
        font-size: 40px;
    }

    /* .our_client_img{
        margin-top: 40px;
      }
      .our_client_img img{
        width: 100%;
      } */
    .our_client_video {
        background: #fff;
        border-radius: 10px;
        padding: 50px 54px 50px 54px;
        margin-top: 40px;
    }

    .our_client_video h2 {
        color: #3292a2;
        font-size: 31px;
        font-weight: 600;
    }

    /* .main-video {
        display: grid;
        gap: 15px;
        align-items: flex-start;
        padding: 5px 5%;
      } */
    h2 {
        color: #D07726 !important;
    }

    .main-video {
        margin-top: 2rem;

        h3 {
            color: #ed7100;
        }

        .more-video-div {
            border-bottom: 1.5px solid #D07726;

            p {
                color: #D07726;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0rem;

            }
        }
    }

    #preview {
        width: 400px;
    }

    .video-list img {
        width: 203px;
    }

    .video-list .vid {
        display: inline-block;
        width: 207px;
        margin: 0 5px;
        cursor: pointer;
        opacity: 0.6;
    }

    .active {
        border: 2px solid #222;
        opacity: 1 !important;
    }

    .description {
        max-width: 10% !important;
        width: 10% !important;
        color: #000 !important;
    }

    @media (max-width: 992px) {
        .our_client {
            width: 100%;
            padding: 100px 50px;
        }
    }

    @media (max-width: 768px) {
        .our_client {
            padding: 100px 25px;
        }
    }

}

.footer_main {
    .footer {
        background: #000;
        padding: 50px 100px;
    }

    .footer_menu_item1_head,
    .footer_menu_item1_menu {
        list-style: none;
        line-height: 30px;
    }

    .footer_menu_item1_menu a {
        color: #fff;
        font-weight: 400;
        text-decoration: auto;
    }

    .footer_menu_item1_head a {
        color: #5c5c5c;
        font-weight: 700;
        text-decoration: auto;
    }

    input {
        border-bottom: 1px solid #D07726;
        background-color: rgba(0, 0, 0, 0) !important;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        padding-left: 0;
    }

    .subscribe-form button {
        background-color: #D07726;
        padding: 10px 40px 13px 40px;
        border: none;
        border-radius: 0;
    }

    .social-media {
        span {
            color: #cecece;
            font-weight: 700;
            line-height: 30px;
            font-size: 18px;
        }

        a {
            margin-right: 1rem;
        }

        img {
            width: 1.3rem;
        }
    }

    @media (max-width: 1400px) {
        .footer {
            padding: 50px 50px;
        }
    }

    @media (max-width: 1200px) {
        .footer_menu_item1 ul {
            padding-left: 1rem;
        }
    }

    @media (max-width: 992px) {
        .footer {
            padding: 50px 30px;
        }
    }

    @media (max-width: 768px) {
        .footer_menu_item1 ul {
            padding-left: 2rem;
        }
    }

    @media (max-width: 480px) {
        .footer {
            padding: 50px 25px;
        }

        .footer_menu_item1 ul {
            padding-left: 0;
        }
    }
}

.custom-main-section {
    font-family: 'Roboto', sans-serif;
    padding: 80px 15px;

    .inner-box {
        border-radius: 5px;
        padding: 24px;
        background-color: #fff;
        box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        width: 50%;
        margin: 0 auto;
    }
}

.track-shipment-div {
    h2 {
        color: green;
        font-weight: 800;
    }

    input {
        text-align: center;
        font-size: 14px;
    }

    a {
        background-color: #f83;
        color: #fff;
        padding: 20px 40px;
        margin-top: 40px;
        border-radius: 2px;
        text-transform: uppercase;
        font-weight: 800;
        text-decoration: none;

    }
}

.login-page-div {
    .inner-box {
        width: 100%;
        max-width: 50%;

        a {
            color: #f83;
            letter-spacing: 0.2px;
            margin-top: 0.25rem;
            font-weight: 500;
        }
    }
}

.form-div {
    background-color: white;
}

.service-card {
    background-color: white;

    .serive-cover-img {
        max-width: 100%;
        width: 100%;
        height: 200px;
        max-height: 200px;
        object-fit: contain;
    }

    .border {
        padding: 4px 16px;
    }
}

.custom-card {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }
}

.single-service-i {
    .about-user-btn {
        background: transparent;
        border: 1px solid #62646a;
        margin-top: 20px;
        font-size: 18px;

        &:hover {
            background: #62646a;
            color: #fff;
        }
    }

    .user-name {
        color: #404145;
        cursor: pointer;
        font-weight: 700;
        margin-right: 8px;
        text-transform: capitalize;
    }

    .content {
        margin-top: 64px;

        h4 {
            font-size: 20px;
            line-height: 28px;
            padding-bottom: 10px;
            color: #404145;
            font-weight: 700;
        }

        p {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .service-title {
        font-size: 3.2rem;
        text-transform: capitalize;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .golden-star {
        font-size: 16px;
        color: #FFB33E;
        padding: 0 2px;
    }

    .total-star {
        font-size: 16px;
        color: #FFB33E;
        padding-left: 3px;
        font-weight: 500;
    }

    .total-review {
        font-weight: 500;
        font-size: 16px;
        padding-left: 3px;
    }

    .user-profile-div {
        width: 30px !important;
    }

    .img-div {
        margin-right: 10px;
    }

    .about-user {
        margin-top: 70px;

        h3 {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 30px;
        }

        .img-div {
            margin-right: 20px;

            img {
                width: 100px;
            }
        }

        .user-business-name {
            font-size: 2rem;
            text-transform: capitalize;
            font-weight: 700;
        }
    }

    .c-main {
        padding-top: 0px !important;
    }

    .container-fluid {
        padding: 0 !important;
        margin: 0 !important;
    }

    .extra-user-detail {
        border: 1px solid #dadbdd;
        padding: 24px;
        margin-top: 30px;

        h5 {
            font-size: 16px;
            color: #74767e;
        }

        h4 {
            font-size: 22px;
            color: #62646a;
            display: block;
            font-weight: 700;
        }
    }

    .reviews-product {
        h2 {
            padding-top: 75px;
            margin-bottom: 35px;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2;
        }

        .all-reviews {
            margin-bottom: 25px;
            background: #fff;
            border-radius: 4px;
            padding: 30px 20px 30px 30px;
            border: 1px solid #e8e8e8;

            .col-md-4 {
                align-self: center;

                h3 {
                    font-size: 44px;
                    font-weight: 500;
                    line-height: 1em;
                    display: block;
                    color: #333;
                }

                h6 {
                    font-size: 15px;
                    color: #888;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
            }

            .col-md-8 {
                border-left: 1px solid #e8e8e8;
                padding-left: 26px;

                .personal-reviews {
                    img {
                        margin: 0 20px 0 0;
                        max-width: 80px;
                        border-radius: 50%;
                    }

                    h5 {
                        font-weight: 600;
                        text-transform: capitalize;
                    }

                    p {
                        color: #888;
                        font-weight: 300;
                        font-size: 16px;
                        margin-bottom: 0.2rem !important;
                    }

                    .comments {
                        line-height: 26px !important;
                        color: #666 !important;
                        margin-top: 1rem !important;
                        font-size: .875rem !important;
                    }
                }
            }
        }
    }

    .price-div {
        border: 1px solid #dadbdd;

        h2 {
            border-bottom: 3px solid #1dbf73;
            color: #1dbf73;
            padding: 16px;
            font-weight: 700;
            font-size: 24px;
            text-align: center;
        }

        .content {
            margin: 0px !important;
            padding: 24px 24px 20px 24px;

            button {
                margin-top: 20px;
                font-size: 18px;
            }
        }
    }
}

.red-heart {
    color: red !important;
}

.singleServicePage__image {
    border-radius: 50px;
    height: 60px;
    width: 60px;
    border: 4px solid white;
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.messages__MakeupVickSpan {
    background-color: #f2f2f2;
    color: #666666;
    /* height: 20px;
    width: 130px; */
    border-radius: 40px;
}

.message-screenY-div {
    .main-heading {
        margin-bottom: 40px;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 400;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .messaging-screen-main {
        background-color: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
        border-radius: 4px;
        margin-bottom: 1rem;

        .top-header {
            padding: 22px 30px;
            border-bottom: 1px solid #eaeaea;

            h4 {
                font-size: 16px;
                color: #333;
                margin: 0;
                font-weight: 600;
            }

            .inner-desc {
                margin-left: 8px;
                font-size: 14px;
                padding: 8px 12px;
                font-weight: 500;
                display: inline-block;
                background-color: #f2f2f2;
                color: #888;
                line-height: 12px;
                border-radius: 50px;
            }
        }

        .chatting-div {
            padding: 30px 30px calc(30px - 1.2rem);
            height: 72vh;
            overflow-y: overlay;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: rgb(169, 169, 169);
                border-radius: 50px;
            }

            .sender,
            .receiver {
                margin-bottom: 1.2rem;
            }

            .avatar-img {
                width: 50px;
                height: 50px;
                margin-top: 3px;
                border-radius: 50px;
            }

            p {
                color: #dd3333;
                background-color: rgba(221, 51, 51, 0.05);
                width: 100%;
                max-width: 60rem;
                padding: 15px 20px;
            }
        }

        .write-text-div {
            padding: 14px;
            border-top: 1px solid #eaeaea;

            i.fa-smile-o {
                position: absolute;
                bottom: .7rem;
                left: .5rem;
                font-size: 18px;
                cursor: pointer;
            }

            .emoji-picker-react {
                position: absolute;
                bottom: 60px;
            }

            textarea {
                border-radius: 8px;
                padding-left: 2rem;
                box-shadow: none;
                height: 2.5rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .fa-paperclip {
                font-size: 20px;
                margin: 0 12px 0 12px;
                cursor: pointer;
            }

            .fa-paper-plane {
                font-size: 18px;
                color: blue;
            }
        }
    }
}



.c-sidebar-nav-link.active {
    color: white !important;
    background-color: #321fdb !important;
}

.particularBooking__dialogButton {
    background-color: #dd3333 !important;
    color: white !important;
}

.signin-modal {

    .modal-header {
        background-color: #f6f6f6;
        position: relative;
        padding: 16px 40px;
        border: 0;

        .close {
            background-color: #e4e4e4;
            border-radius: 100%;
            padding: 0rem 0.65rem 0.3rem;
            position: absolute;
            right: 2rem;
            top: 1.8rem;

            span {
                font-size: 32px;
            }
        }
    }

    .modal-body {
        padding: 40px;

        ul li {
            margin-right: 2px;

            a {
                font-weight: 600;
                font-size: 15px;
                color: #666666;
                transition: .3s all ease-in-out;
                padding: 0 8px 14px;
                display: inline-block;

                &:hover {
                    color: #dd3333;
                    border-color: white white #dd3333;
                }
            }

            a.active {
                border-color: white white #dd3333;
                color: #dd3333;
            }
        }

        #logIn,
        #newCustomer {

            input.form-control,
            select {
                line-height: 51px;
                padding: 0 20px;
                outline: none;
                font-size: 15px;
                color: #808080;
                margin: 0 0 16px 0;
                max-width: 100%;
                width: 100%;
                box-sizing: border-box;
                display: block;
                background-color: #fff;
                border: 1px solid #dbdbdb;
                box-shadow: 0 1px 3px 0px rgba(0, 0, 0, .06);
                font-weight: 500;
                border-radius: 3px;
                margin-top: 8px;
                padding-left: 45px;
                height: 53px;
            }

            .country-select-signin {
                margin-bottom: 16px;
            }

            .css-1s2u09g-control {
                padding-left: 36px !important;
            }

            i {
                position: absolute;
                color: #808080;
                top: 1.2rem !important;
                left: 15px;
            }

            a {
                color: #666666;
                font-size: 15px;
                text-decoration: none;

                &:hover {
                    color: #dd3333;
                }
            }

            .btn {
                color: white;
                display: block;
                width: fit-content;
                background-color: #dd3333;
                border-radius: 50px;
                min-width: 120px;
                font-weight: 600;
                margin-top: 1.5rem;
                padding: 9px;
            }

            .form-check-input {
                height: 20px;
                width: 20px;
                margin-top: 0;
            }

            .customer-btn {
                position: static;
                color: #dd3333;
                border-bottom: 1px solid #dd3333;
                font-weight: 600;
                padding: 10px;
                text-align: center;
                margin-bottom: 2rem;
                display: block;

                i {
                    position: static;
                    color: #dd3333;
                    margin-right: .25rem;
                }
            }
        }
    }
}

.sender,
.receiver {
    margin-bottom: 1.2rem;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.custom-card {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .job-details {
        h3 {
            margin-bottom: 15px;
        }

        p {
            font-size: 16px !important;
        }
    }
}

.attachments-div {
    background-color: #eee;
    background-color: #eee;
    border-radius: 12px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-13 {
    font-size: 13px;
}

.fs-24 {
    font-size: 24px;
}

.all-jobs {
    .particular-jobs {
        padding: 20px 0px;
        border-bottom: 1px solid #e4ebe4;

        h3 {
            font-size: 20px;
            margin-bottom: 20px;
            font-weight: 700;
        }

        &:hover {
            background: #eee;
            border-radius: 15px;
            transition: all 1s;
            padding: 20px 10px;
        }

        .color {
            color: #3c4b64;
        }
    }
}

.proposals-information {
    .business-name {
        font-size: 16px;
        line-height: 0.7rem;
        font-weight: 700;
    }

    .client-name {
        font-size: 18px;
        font-weight: 700;
    }

    .bid-amount {
        font-size: 16px;
        font-weight: 500;
    }

    .content {
        border-top: 1px solid #e4ebe4;
        margin-top: 15px;
        padding-top: 15px;

        p {
            font-size: 14px;
        }
    }
}

.sender {
    margin-bottom: 1.2rem;

    .avatar-img {
        width: 50px;
        height: 50px;
        margin-top: 3px;
        border-radius: 50px;
    }
}

.main-information-div {
    margin-bottom: 30px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgb(208 119 38 / 8%);
    background-color: #FEF5E6;

    h1 {
        color: #F79F00;
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: -1.2px;
    }

    p {
        color: #F79F00;
        font-size: 17px;
        font-weight: 600;
    }

    button {
        background: #F79F00;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        box-shadow: none !important;
        outline: 0;

        &:hover {
            color: #fff;
        }
    }
}

.wallet-table-img {
    max-width: 80px;
    height: auto;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 50%;
}

.main-div-wallet {
    .modal-header {
        img {
            width: 6rem;
        }

        p {
            margin: 0.4rem 0 0;
            font-size: 1.1rem;
            font-weight: 500;
            color: slategray;
        }

        button {
            position: absolute;
            top: -5px;
            right: -6px;
            background: #F79F00;
            color: white;
            opacity: 1;
            border-radius: 50px;
            padding: 2px 4px 6px;
            line-height: 0;
            font-size: 20px;
        }
    }

    .modal-footer {
        button {
            background: #F79F00;
            border: 0;
            font-size: 17px;
            padding: 6px 46px;
            letter-spacing: 0.5px;
            border-radius: 6px;
        }
    }

    .custom-input-wallet {
        font-size: 13px;
        background: #F9F9F9;
        padding: 1.3rem 10px;
    }
}

.notification-div {

    .particular-notification-div {
        padding: 20px;
        border-bottom: 1px solid #e4ebe4;

        i {
            font-size: 22px;
        }

        .heading-p {
            font-size: 16px;
            font-weight: 600;
        }

        .link-notification {
            color: #F79F00;
            border: 2px solid #F79F00;
            border-radius: 40px;
        }
    }
}

.job-detail-div {

    .particular-job-div {
        padding: 20px;
        border-bottom: 1px solid #e4ebe4;

        i {
            font-size: 22px;
        }

        .heading-p {
            font-size: 14px;
            font-weight: 400;
        }

        h4 {
            color: #000;
            font-size: 18px;
            font-weight: 800;
        }

        .link-job {
            color: #F79F00;
            border: 2px solid #F79F00;
            border-radius: 40px;
        }

        .img-div {
            width: 10%;
            padding-right: 20px;

            img {
                width: 80px;
                border: 1px solid #F79F00;
                padding: 9px;
                background-color: #F79F00;
                border-radius: 10px;
            }

            i {
                font-size: 50px;
                border: 1px solid #F79F00;
                padding: 9px;
                background-color: #F79F00;
                border-radius: 10px;
            }
        }

        p {
            font-size: 14px;
        }
    }
}

.particular-work-card {
    border-bottom: 1px solid #e4ebe4;
    padding: 0px 20px 15px 20px;

    .job-title {
        font-size: 15px;
        font-weight: 700;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

@media (max-width: 768px) {
    .home-slider-main-Y {
        .slider_container {
            padding: 0 45px;
            max-width: 100%;
        }
    }
}

.termsPage {
    color: black;
    background-color: white;
    padding: 40px 100px;

    h3 {
        font-size: 20px;
        font-weight: 600;
        font-style: italic;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        margin-top: 2.5rem;
    }

    h4 {
        font-weight: 700;
        margin-top: 1.7rem;
    }

    p,
    li {
        font-size: 15.5px;
    }
}

.loader-div {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
    cursor: progress;

    img {
        max-width: 28rem;
        width: 100%;
    }
}

#my-profile-page {
    .profile-img {
        border-radius: 10px;
        margin-bottom: 35px;
    }

    .btn {
        color: black;
        font-size: large;
        font-weight: 500;
        border-radius: 10px;
        padding: 12px;
        margin: 10px 9px;
        width: 30%;
        text-align: center;
    }

    .btn:hover {
        transition: all .40s ease-in-out 0s;
    }

    .email-h3 {
        padding: 20px 0 13px 0;
        margin: 14px 10px;
        border-top: 1px solid #00000061;
    }

    .user-profile {
        height: 100px;
        width: 100px;
        background-color: antiquewhite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        position: relative;
    }

    .edit-section {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: wheat;
        position: absolute;
        bottom: 8px;
        right: -2px;
    }

    @media only screen and (min-width: 200px) and (max-width: 575px) {

        .row h3 {
            margin-top: 35px;
            margin-bottom: 20px;
            padding: 10px;
            text-decoration: underline;
            text-align: center;
        }

        .btn {
            color: black;
            font-size: small;
            font-weight: 500;
            border-radius: 10px;
            padding: 12px;
            margin: 10px 11px;
            width: 328px;
        }
    }
}

#shipper-dashboard{
    
    :root{
        --primary-color: #A82C48;
        --secondry-color: #481a24;
        --third-color: #89f336;
        --white-color: #fff;
        --background-grey: #f7f8f6fb;
        --primari-font: 'Roboto',sans-serif;
        --secondry-font: 'Quicksand', sans-serif;
    }
    
    .main-div:hover{
        background-color: var(--background-grey);
        
    }
    
    .headline{
        color: rgba(0, 0, 0, 0.716);
        font-family: var(--primari-font);
        font-weight: 700;
    }
    
    .headline:hover{
        color: var(--primary-color);
        text-decoration: underline;
        transition: all 0.2s ease-in-out 0s;
    }
    
    .btn{
        color: var(--primary-color);
        border: 2px solid var(--primary-color);
        font-weight: 500;
        font-size: 16px;
        text-decoration: none;
        font-family: var(--primari-font);
    }
    
    .img-section{
        height: 85%;
        width: 45%;
    }
    
    .get-Started{
        border:0.5px solid #481a24;
        border-radius: 5px;
    }

    @media only screen and (min-width: 200px) and (max-width: 575px){
        .main-div{
            text-align: center;
            justify-content: center;
            line-height: 20px;
        }
        .second-div{
            margin-top: 30px;
            margin-bottom: 30px;
            line-height: 20px;
        }
        .btn{
            color: var(--primary-color);
            border: 2px solid var(--primary-color);
            font-weight: 500;
            font-size: 15px;
            text-decoration: none;
            font-family: var(--primari-font);
        }
    
        .banner{
            font-size: 15px;
            padding: 15px;
        }
    
        .img-section{
            height: 75%;
            width: 85%;
            padding-top: 35px;
        }
         
    }
    
    @media only screen and (min-width: 576px) and (max-width: 991px){
        .main-div{
            text-align: center;
            justify-content: center;
            line-height: 20px;
        }
        .second-div{
            margin-top: 30px;
            margin-bottom: 30px;
            line-height: 20px;
        }
        .btn{
            color: var(--primary-color);
            border: 2px solid var(--primary-color);
            font-weight: 500;
            font-size: 15px;
            text-decoration: none;
            font-family: var(--primari-font);
        }
    
        .banner{
            font-size: 20px;
            padding: 20px;
        }
        
        .img-section{
            height: 85%;
            width: 79%;
            padding: 20px;
        }
         
    }
}